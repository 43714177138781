import React from 'react'

import Layout from '../components/Layout'

export default function App() {
  return (
    <Layout>
      <h1 id="title" className="text-5xl font-medium m-0 mb-2">
        Hello!
        <span role="img" aria-label="high-five">
          👋
        </span>
      </h1>

      <h2 className="text-5xl font-medium m-0 mb-6">I'm Anton Halim</h2>

      <p className="text-lg leading-10 mb-5">
        Welcome to my site, I'm pleased to meet you here.
        <br />
        Check here to see <a href="/now">what I'm currently up to</a>.
        <br />
        Contact me here at{' '}
        <a href="mailto:hi@antonhalim.com?subject=Hi Anton!">
          hi@antonhalim.com
        </a>{' '}
        to chat.
        <br />
        We can connect on{' '}
        <a
          href="https://linkedin.com/in/antonhalim"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn
        </a>
        , or my find my{' '}
        <a href="/resume.pdf" type="image/pdf">
          résumé here
        </a>
        .
      </p>

      <p className="text-lg leading-8 mb-5">
        Thanks for stopping by!
        <br />- Anton
      </p>
    </Layout>
  )
}
